.App {
  text-align: center;
}

.App-logo {
  /* height: 40vmin; */
  width: 30%;
  max-width: 1000px;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #FD1593;
}

a {
  padding: 10px;
  transition: transform 0.3s ease-in-out; /* Adding a transition for smooth effect */
  text-decoration: none;
}

a:hover {
  transform: scale(1.1); /* Increasing the size by 1.1x on hover */
}


/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
